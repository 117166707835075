<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-6">
            <v-btn
              color="teal lighten-2"
              medium
            >
              Add
            </v-btn>
            <v-btn
              color="red"
              medium
            >
              Delete
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Keyword ID
            </v-subheader>
            <v-text-field
              v-model="filterKeywordId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Keyword
            </v-subheader>
            <v-text-field
              v-model="filterKeyword"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
          >
            Apply
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          v-if="tableObjects"
          ref="objecttable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="objectHeaders"
          :items="tableObjects"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          show-select
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-edit"
                @click="objectAction('edit object', item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                elevation="0"
                class="action-btn"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        objects: [
          {
            id: 562,
            mainKeyword: 'Kran',
            metaKeywords: 'Lastenkran, Hebekran, Baukran, Motorkran, Minikran',
            optionalKeywords: 'Minikran',
            objectCount: 25,
            requestCount: 132,
            rentalFees: '5,940.00',
            averageRentalFee: '45.00',
          },
          {
            id: 563,
            mainKeyword: 'Kran 2',
            metaKeywords: 'Lastenkran, Hebekran, Baukran, Motorkran, Minikran',
            optionalKeywords: 'Minikran',
            objectCount: 26,
            requestCount: 133,
            rentalFees: '5,941.00',
            averageRentalFee: '46.00',
          },
          {
            id: 564,
            mainKeyword: 'Kran 3',
            metaKeywords: 'Lastenkran, Hebekran, Baukran, Motorkran, Minikran',
            optionalKeywords: 'Minikran',
            objectCount: 27,
            requestCount: 134,
            rentalFees: '5,942.00',
            averageRentalFee: '47.00',
          },
        ],
        tableObjects: null,
        selected: [],
        filterKeywordId: '',
        filterKeyword: '',
        appliedFilters: {},
        objectsTotal: 0,
        objectHeaders: [
          { text: 'Keyword ID', value: 'id', align: 'center', width: '100px' },
          { text: 'Main Keyword', value: 'mainKeyword' },
          { text: 'Meta Keywords', value: 'metaKeywords' },
          { text: 'Optional Keywords', value: 'optionalKeywords' },
          { text: 'Object Count', value: 'objectCount', align: 'center', width: '100px' },
          { text: 'Request Count', value: 'requestCount', align: 'center', width: '100px' },
          { text: 'Rental Fees', value: 'rentalFees', align: 'center', width: '100px' },
          { text: 'Average Rental Fee', value: 'averageRentalFee', align: 'center', width: '100px' },
          { text: 'Action', value: 'action', align: 'center', width: '110px' },
        ],
        localWidths: [],
      }
    },

    watch: {
      filterKeywordId: {
        handler () { this.filterObjects('filterKeywordId', 'id', 'text') },
      },
      filterKeyword: {
        handler () { this.filterObjects('filterKeyword', 'mainKeyword', 'text') },
      },
    },

    created () {
      this.tableObjects = this.objects
    },

    methods: {
      objectAction (val, object) {
        if (val.toLowerCase() === 'edit object') {
          this.$router.push(`object-keywords/${object.id}`)
        }
      },
      clearFilters () {
        this.filterKeywordId = ''
        this.filterKeyword = ''
        this.tableObjects = this.objects
      },
      filterObjects (filterName, tableField, type) {
        if (this[filterName]) {
          if (this.appliedFilters[filterName]) {
            this.appliedFilters[filterName].applied = true
          } else {
            this.appliedFilters[filterName] = {
              applied: true,
              tableField: tableField,
              type: type,
            }
          }
        } else {
          this.appliedFilters[filterName].applied = false
        }

        const trigger = Object.keys(this.appliedFilters).filter(el => this.appliedFilters[el].applied)

        if (trigger.length) {
          let filteredObjects = [...this.objects]

          Object.keys(this.appliedFilters).forEach(obj => {
            if (this.appliedFilters[obj].applied && this[obj]) {
              filteredObjects = this.filterData(filteredObjects, obj, this.appliedFilters[obj].tableField, this.appliedFilters[obj].type)
            }
          })

          this.tableObjects = filteredObjects
        } else {
          this.tableObjects = this.objects
        }
      },

      filterData (data, filterName, tableField, type) {
        let filteredData = data
        switch (type) {
          case 'text':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase().includes(this[filterName].toString().toLowerCase()))
            break
          case 'select':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase() === this[filterName].toString().toLowerCase())
            break
          default:
            break
        }
        return filteredData
      },
    },
  }
</script>

<style lang="scss">
.fixed-columns-table .v-data-table__wrapper table th:nth-child(2),
.fixed-columns-table .v-data-table__wrapper table td:nth-child(2) {
  left: calc(68px) !important;
}
</style>
